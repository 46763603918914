import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { database } from '../firebase';
import { ref, get, set, update } from 'firebase/database';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const createPlayerCombinationKey = (players) => {
    return players.map(player => player.id).sort().join('_');
}

export const calculateGameStats = async (winningPlayer, stats, players, playerWords, gameId) => {
    const newStats = {...stats};
    //const newStats = {};
    if(newStats && newStats['gameStats']) {
        return newStats;
    }

    // Calculate stats for this game
    let longestWord = '';
    let longestWordPlayer = '';
    let mostWords = 0;
    let mostWordsPlayer = '';
    for (let player of players) {
        if(playerWords[player.id]) {
            let wordCount = playerWords[player.id].length;
            if (wordCount > mostWords) {
                mostWords = wordCount;
                mostWordsPlayer = player.name;
            }
            for (let word of playerWords[player.id]) {
                if(word.length > longestWord.length) {
                    longestWord = word;
                    longestWordPlayer = player.name;
                }
            }
        }
        
            
        newStats['gameStats'] = {'winner':{'points':winningPlayer.score, 'playerName':winningPlayer.name},
                                'longestWord':{'word':longestWord,'playerName':longestWordPlayer},
                                'mostWords':{'count':mostWords,'playerName':mostWordsPlayer}
        }
    }
        
    // Calculate stats for overall combination of players
    const playerCombinationKey = createPlayerCombinationKey(players);
    let recordData = {};

    const recordRef = ref(database, `records/${playerCombinationKey}`);

            
    try {
        const snapshot = await get(recordRef);
        recordData = snapshot.val();
        
        if (!recordData) {
            console.log("Creating new record");
            // New record (i.e. first time this combo of players is playing each other)
            const wins = players.map(player => ({
                playerName: player.name,
                id: player.id,
                wins: player.id === winningPlayer.id ? 1 : 0
            }));
            recordData = {
                wins: wins,
                gameCount: 1,
                longestWord: {word: longestWord,playerName:longestWordPlayer},
                mostWords: {count: mostWords, playerName: mostWordsPlayer},
                lastGame: gameId
            }
            await set(recordRef, recordData);
        } else {
            console.log("Updating existing record");
            // Make sure we haven't already processed this game
            if (recordData.lastGame !== gameId) {
                
                recordData.gameCount += 1;

                // Update the win count and display names for the players
                for(let w of recordData.wins) {
                    for(let player of players) {
                        if (w.id === player.id) {
                            w.name = player.name;   // Update player name in case player changed it during this game
                            // Increment count for winning player
                            if(w.id === winningPlayer.id) {
                                w.wins +=1;
                            }
                        }
                    }
                }

                // Check if new records
                if(recordData.longestWord.word.length < longestWord.length) {
                    recordData.longestWord.word = longestWord;
                    recordData.longestWord.playerName = longestWordPlayer;
                }
                if(recordData.mostWords.count < mostWords) {
                    recordData.mostWords.count = mostWords;
                    recordData.mostWords.playerName = mostWordsPlayer;
                }

                recordData.lastGame = gameId;

                await update(recordRef, recordData);
            }
        }

    } catch (error) {
        console.error("Error processing game: ", error);
    }

            

    newStats['recordStats'] = recordData;
    
    // Calculate all-time stats for individual players
    // TODO

    return newStats;
}

const GameOver = ({ stats, onGameOverClosed }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onGameOverClosed();
    };

    if (!stats) {
        return <div>Loading...</div>; // Or any other loading state representation
    }
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="game-over-modal-title"
            aria-describedby="game-over-modal-description"
        >
            <Box sx={style}>
                <Typography id="game-over-modal-title" variant="h6" component="h2" gutterBottom sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    Game Over: {stats.gameStats.winner.playerName} wins! 
                </Typography>
                {stats.gameStats &&
                    <>
                        <Typography variant="subtitle1">Game Stats</Typography>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Longest Word</TableCell>
                                    <TableCell>{stats.gameStats.longestWord.word} ({stats.gameStats.longestWord.playerName})</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Most Words</TableCell>
                                    <TableCell>{stats.gameStats.mostWords.count} ({stats.gameStats.mostWords.playerName})</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                }
                {stats.recordStats && stats.recordStats.gameCount > 1 &&
                    <>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>All-Time</Typography>
                        <Table size="small">
                            <TableBody>
                                {stats.recordStats.wins.map((player) => (
                                    <TableRow key={player.playerName}>
                                        <TableCell>{player.playerName} Wins</TableCell>
                                        <TableCell>{player.wins}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>All-time Longest Word</TableCell>
                                    <TableCell>{stats.recordStats.longestWord.word} ({stats.recordStats.longestWord.playerName})</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>All-time Most Words</TableCell>
                                    <TableCell>{stats.recordStats.mostWords.count} ({stats.recordStats.mostWords.playerName})</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                }
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={handleClose}>Close</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default GameOver;
