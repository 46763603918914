// Guest.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import HomeLogo from './HomeLogo';

const Guest = () => {
    const [name, setName] = useState('');

    const handleNameChange = (e) => setName(e.target.value);
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const gameID = searchParams.get('game');

    const handleSubmit = () => {
        signInAnonymously(auth)
            .then(() => {
                window.localStorage.setItem('nameForSignIn', name);
                console.log("Name: " + name);
                // Wait for auth state to change
                const unsubscribe = onAuthStateChanged(auth, (user) => {
                    console.log("Here: " + window.localStorage.getItem('nameForSignIn'));
                    if (user) {
                        navigate(`/finishSignUp?game=${gameID}`);
                        unsubscribe(); // Unsubscribe once we receive the user
                    }
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <HomeLogo />
            <Container maxWidth="sm">
                <Box my={4} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4" gutterBottom>
                        Guest Sign In
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Name"
                        value={name}
                        onChange={handleNameChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ marginTop: 16 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default Guest;
