import { getAuth, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';

const FinishSignUp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();
    const [user, setUser] = useState(null);

    const searchParams = new URLSearchParams(location.search);
    const gameID = searchParams.get('game');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe(); // Cleanup subscription
    }, []);

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    const urlParams = new URLSearchParams(window.location.search);
                    const emailName = email.includes("@") ? email.split("@")[0] : "";
                    const name = urlParams.get('name') || emailName || 'Anonymous';

                    const userRef = ref(database, `users/${result.user.uid}`);
                    set(userRef, { email, name });

                    window.localStorage.removeItem('emailForSignIn');
                    if(gameID) {
                        navigate(`/join/${gameID}`);
                    } else {
                        navigate('/');
                    }
                    
                })
                .catch((error) => {
                    console.error('Error signing in with email link', error);
                });
        }
    }, [user, navigate, gameID, auth]);

    useEffect(() => {
        if (user && user.isAnonymous) {
            const name = window.localStorage.getItem('nameForSignIn') || 'Anonymous';
            
            // Store the user's data in Firestore
            const userRef = ref(database, `users/${user.uid}`);
            set(userRef, { name });

            window.localStorage.removeItem('nameForSignIn');
            navigate(`/join/${gameID}`);
        }
    }, [user, navigate, gameID]);

    return (
        <div>
            <h1>Finishing Sign Up...</h1>
            {/* Additional content or loading spinner */}
        </div>
    );
};

export default FinishSignUp;
