import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const RevealedLetters = ({ letters, selectedLetters, onLetterSelect, currentDelay, onDelayFinished, onRevealLetter, 
    currentTurnPlayer, thisPlayer, letterPoolCount, currentTurn, players, settings }) => {

    const letterLimit = (settings.solo && settings.solo.isSolo) ? settings.solo.windowSize : 1000; // Let the user pick from at most this number of letters
    const fadedLimit = 3; // In solo mode, show this number of letters as faded
    const [prevLetters, setPrevLetters] = useState([]);

    const tileStyle = {
        backgroundColor: '#eeeeee', // grey background
        color: '#333',
        margin: '3px',
        border: '1px solid #888888',
        fontWeight: 'bold',
        borderRadius: '5px',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        padding: '0',
        width: '40px',
        minWidth: '40px',
        height: '40px',
        fontSize: '20px',
        textTransform: 'none', // Prevents uppercase transformation in MUI Button
        };

    // Styles for the container of the countdown timer
    const countdownContainerStyle = {
        display: 'flex', // Use flexbox
        justifyContent: 'center', // Center content horizontally
        alignItems: 'center', // Center content vertically (optional)
        height: '100px', // Adjust as needed for vertical spacing
        margin: 'auto' // Ensure margin is auto for horizontal centering
    };

    const remainingCountStyle ={
        fontSize: '12px',
        color: letterPoolCount <=5  ? 'red' : 'grey'
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setPrevLetters(letters);
        }, 2000); // Duration of the wiggle animation
        return () => clearTimeout(timer);
    }, [letters]);

    // Function to check if a letter is new
    const animateLetter = (letter, index) => {
        // If it's the first letter in the list and we've added another letter, let's animate
        return index === 0 && prevLetters.length < letters.length;
    };

    const showRevealButton = () => {
        return (settings.solo && settings.solo.isSolo) ||        // If solo mode, show the reveal button
        (!(currentDelay > 0) && players.length > 1); // Or if not solo mode, make sure no delay and there is more than one player
    }

    const getUniqueKey = (letter, index) => {
        // Change the key only for the new letter
        if (index === 0 && prevLetters.length < letters.length) {
            return `${letter}-${new Date().getTime()}-${index}`;
        }
        return `${letter}-${index}`;
    };

    const shouldSlide = (index) => {
        // Slide if it's not the first letter (new letter)
        return index !== 0 && prevLetters.length < letters.length;
    };

  return (
    <div>
        <style>
        {`
            @keyframes wiggle {
                0%, 100% { transform: rotate(-3deg); }
                50% { transform: rotate(3deg); }
            }

            .wiggle {
                animation: wiggle 0.2s ease-in-out 10; /* 20 iterations for 2 seconds total */
            }

            @keyframes fadeEffect {
                from { background-color: yellow; }
                to { background-color: #eeeeee; }
            }

            .fade {
                animation: fadeEffect 2s ease-out; /* Adjust duration and timing as needed */
            }
            .wiggleAndFade {
                animation:
                    wiggle 0.2s ease-in-out 10, /* Wiggle: duration, timing, and iteration count */
                    fadeEffect 2s ease-out;      /* Fade: duration and timing */
            }

            @keyframes slideRight {
                from { transform: translateX(-40px); } /* Assuming each tile is 40px wide */
                to { transform: translateX(0px); }
            }
            
            .slideRight {
                animation: slideRight 0.3s ease-out; /* Adjust duration and timing as needed */
            }            
        `}
        </style>
        {currentDelay > 0 && (
        <div style={countdownContainerStyle}>
            <CountdownCircleTimer
            isPlaying={currentDelay > 0}
            duration={currentDelay}
            size={50}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
            onComplete={() => {
                onDelayFinished();
            }}
            >
            {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
        </div>
        )}

        {showRevealButton() && (
        <div style={{ textAlign: 'center' }}>
            {/* New letter button or show whose turn it is */}
            {currentTurnPlayer && (
            <span>
                {currentTurnPlayer.id === thisPlayer.id ? (
                <Button
                    onClick={onRevealLetter}
                    variant="contained"
                    color="primary"
                    style={{ margin: '0 10px 5px 0' }}
                >
                    New Letter
                </Button>
                ) : (
                <span style={{ margin: '10px' }}>{currentTurnPlayer.name}'s turn</span>
                )}
            </span>
            )}

            {letters.slice(0,letterLimit + fadedLimit).map((letter, index) => (
                <Button
                    key={getUniqueKey(letter, index)} // Use the unique key here
                    onClick={() => onLetterSelect(letter, index)}
                    style={{
                        ...tileStyle,
                        ...(selectedLetters.some(l => l.letter === letter && l.index === index)
                            ? { backgroundColor: '#FF9800' }
                            : {}),
                        ...(currentDelay > 0 ? { opacity: 0.5, cursor: 'not-allowed' } : {}),
                        ...(index >= letterLimit ? { opacity: 0.5, cursor: 'not-allowed' } : {})
                    }}
                    className={`${animateLetter(letter, index) ? 'wiggleAndFade' : ''}
                    ${shouldSlide(index) ? 'slideRight' : ''}`}
                    disabled={currentDelay > 0 || currentTurn === -2}
                >
                    {letter}
                </Button>
            ))}

            {currentTurn > -2 && (
            <Typography variant="subtitle2" style={remainingCountStyle}>
                {letterPoolCount} letters left
            </Typography>
            )}
        </div>
        )}
    </div>
  );
};

export default RevealedLetters;