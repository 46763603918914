import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { database, auth } from '../firebase';
import { fetchPlayerData } from './utils';
import { ref, set, get, update } from 'firebase/database';
import { Container, Box, Typography, CircularProgress } from '@mui/material';
import seedrandom from 'seedrandom';

const JoinGamePage = () => {
    const { gameId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loadingMessage, setLoadingMessage] = useState('Preparing game...');
    const initializedRef = useRef(false);
    const [isAuthLoading, setIsAuthLoading] = useState(true);

    const searchParams = new URLSearchParams(location.search);
    const solo = searchParams.get('solo');
    const poolSize = searchParams.get('pool');
    const windowSize = searchParams.get('window');

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            // If not a logged in user, get them to log in
            if (!user) {
                navigate(`/login?game=${gameId}`);
            }
            setIsAuthLoading(false);
        });

        return () => unsubscribe(); // Cleanup subscription
    }, [navigate, gameId]);

    useEffect(() => {
        
        if (isAuthLoading) return;
        
        if (initializedRef.current) {
            return;
        }
        
        initializedRef.current = true;
        const joinOrCreateGame = async () => {

            const gameRef = ref(database, `games/${gameId}`);
            setLoadingMessage('Checking game status...');

            try {
                const snapshot = await get(gameRef);
                let gameData = snapshot.val();
                
                if (!gameData) {
                    setLoadingMessage('Creating new game...');
                    gameData = await initializeGameState(gameId);
                } else {
                    setLoadingMessage('Joining existing game...');
                    await addPlayerToGame(gameData, gameRef);
                }
                navigate(`/game/${gameId}`);
            } catch (error) {
                console.error("Error processing game: ", error);
            }
        };

        joinOrCreateGame();

        const initializeGameState = async (gameId) => {
            const initialLetterPool = generateLetterPool(); // Your function to generate the initial pool of letters
            console.log(initialLetterPool);
            const newPlayer = await createPlayer();
            let soloSettings = {};
            let revealedLetters = [];
            if(parseInt(solo) === 1) {
                const wSize = parseInt(windowSize);
                const pSize = parseInt(poolSize);
                soloSettings = {isSolo: true,
                                poolSize: pSize ,
                                windowSize: wSize};
                revealedLetters = initialLetterPool.splice(0, wSize);   
            }

            const gameState = {
                players: [newPlayer], 
                messages: {}, // Empty object for messages
                currentTurn: 0,
                letterPool: initialLetterPool,
                revealedLetters: revealedLetters,
                settings: {solo:soloSettings}
            };
        
            const gameRef = ref(database, `games/${gameId}`);
            await set(gameRef, gameState);
            return gameState;
        };

        const addPlayerToGame = async (gameData, gameRef) => {
            const existingPlayers = gameData.players || [];

            // First check that this player isn't already in the player set
            for (let player of existingPlayers) {
                if(player.id === auth.currentUser.uid) {
                    return;
                }
            }

            const newPlayer = await createPlayer();
            if (!newPlayer) {
                // Handle the case where player creation fails
                return;
            }

            const updatedPlayers = [...existingPlayers, newPlayer];
        
            // Update the game data with the new player
            await update(gameRef, { ...gameData, players: updatedPlayers });
        };   
    }, [gameId, isAuthLoading, navigate, solo]);

    const generateLetterPool = () => {
        //const bananagrams = [['A',13],['B',3],['C',3],['D',6],['E',18],['F',3],['G',4],['H',3],['I',12],['J',2],['K',2],['L',5],['M',3],['N',8],['O',11],['P',3],['Q',2],['R',9],['S',6],['T',9],['U',6],['V',3],['W',3],['X',2],['Y',3],['Z',2]];
        //const scrabble = [['A',9],['B',2],['C',2],['D',4],['E',12],['F',2],['G',3],['H',2],['I',9],['J',1],['K',1],['L',4],['M',2],['N',6],['O',8],['P',2],['Q',1],['R',6],['S',4],['T',6],['U',4],['V',2],['W',2],['X',1],['Y',2],['Z',1]];
        const snatch = [['A',5],['B',2],['C',4],['D',4],['E',12],['F',4],['G',2],['H',5],['I',5],['J',1],['K',1],['L',5],['M',4],['N',5],['O',6],['P',3],['Q',1],['R',5],['S',5],['T',7],['U',4],['V',2],['W',3],['X',1],['Y',3],['Z',1]];
        //const short = [['A',3],['T',3],['C',3],['S',3]];
        //const shortest = [['A',1],['T',1],['C',1],['S',1]];
        const distribution = snatch;
        let pool = [];
        distribution.forEach(([letter, count]) => {
            for (let i = 0; i < count; i++) {
                pool.push(letter);
            }
        });
        return shuffleLetters(pool, poolSize);
    };

    function shuffleArray(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;
    
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
    
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
    
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
    
        return array;
    }
    
    function seededShuffleArray(array, seed) {
        let currentIndex = array.length, temporaryValue, randomIndex;
        const rng = seedrandom(seed);

        // While there remain elements to shuffle
        while (currentIndex !== 0) {
            // Pick a remaining element
            randomIndex = Math.floor(rng() * currentIndex);
            currentIndex -= 1;

            // Swap it with the current element
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;

            // Change seed to ensure the next random number will be different
            seed = randomIndex;
        }

        return array;
    }

    function shuffleLetters(letters, poolSize) {        
        // If poolSize is specified, means we're dealing with a solo game, so let's get 
        const soloPoolLength = parseInt(poolSize);
        if(soloPoolLength > 0) {
            const date = new Date();
            const seed = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
            const shuffledArray = seededShuffleArray([...letters], seed);
            return shuffledArray.slice(0, soloPoolLength);
        } else {
            return shuffleArray([...letters]);
        }
    }

    const createPlayer = async () => {
        const newPlayerData = await fetchPlayerData(auth.currentUser.uid);
        if (!newPlayerData) {
            console.error('Failed to fetch player data');
            return null; // Handle this case appropriately
        }
    
        const newPlayer = {
            'id': auth.currentUser.uid,
            'name': newPlayerData.name,
            'score': 0,
            'delay': 0
        };
    
        return newPlayer;
    };
    
    return (
        <Container maxWidth="sm">
            <Box my={4} display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h4" component="h2" gutterBottom>
                    {loadingMessage}
                </Typography>
                <CircularProgress />
            </Box>
        </Container>
    );
};

export default JoinGamePage;