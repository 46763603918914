import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const SoloSettingsModal = ({ open, handleClose, defaultPoolSize, defaultWindowSize, handleUpdate }) => {
  const [poolSize, setPoolSize] = useState(defaultPoolSize);
  const [windowSize, setWindowSize] = useState(defaultWindowSize);

  const handleSave = () => {
    handleUpdate(poolSize, windowSize);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Game Settings
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="Letter Pool Size"
          value={poolSize}
          onChange={(e) => setPoolSize(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Letter Window Size"
          value={windowSize}
          onChange={(e) => setWindowSize(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: '16px' }}
        >
          Update
        </Button>
      </Box>
    </Modal>
  );
};

export default SoloSettingsModal;
