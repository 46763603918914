import React, { useState } from 'react';
import { auth } from '../firebase';
import { sendSignInLinkToEmail } from "firebase/auth";
import { TextField, Button, Container, Box, Typography, Link } from '@mui/material';
import HomeLogo from './HomeLogo';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);

    const handleSubmit = () => {
        const actionCodeSettings = {
            url: `${window.location.origin}/finishSignUp?name=${encodeURIComponent(name)}`,
            handleCodeInApp: true,
        };

        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
                alert('Check your email for the sign-up link');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <HomeLogo />
            <Container maxWidth="sm">
                <Box my={4} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4" gutterBottom>
                        Create Player
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Name"
                        value={name}
                        onChange={handleNameChange}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ marginTop: 16 }}
                    >
                        Submit
                    </Button>
                    <Typography variant="body1" style={{ marginTop: 16 }}>
                        or <Link href="/login">Login</Link>
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default SignUp;