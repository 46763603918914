// src/components/Leaderboard.js
import React, { useEffect, useState } from 'react';
import { database } from '../firebase';
import { ref, onValue } from 'firebase/database';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const recordsToShow = 10; // Number of records to display

  useEffect(() => {
    const dbRef = ref(database, 'dailyBestScores');

    const unsubscribe = onValue(dbRef, (snapshot) => {
      const data = [];
      snapshot.forEach((childSnapshot) => {
        data.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      // Sort and limit the data
      data.sort((a, b) => {
        // Sort by dateString, then poolSize, then bestScore
        // Similar sorting logic as before
      });

      setLeaderboardData(data.slice(0, recordsToShow)); // Limit to X records
    });

    return () => unsubscribe();
  }, []);

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px', padding: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Player</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Pool Size</TableCell>
            <TableCell>Window Size</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboardData.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>{entry.dateString}</TableCell>
              <TableCell>{entry.playerName}</TableCell>
              <TableCell>{entry.bestScore}</TableCell>
              <TableCell>{entry.poolSize}</TableCell>
              <TableCell>{entry.windowSize}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Leaderboard;
