import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const LetterTile = ({ letter, fontSize, dimension }) => (
  <Paper 
    elevation={3} // Add elevation for depth
    style={{
      cursor: 'move',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: dimension, // Use dynamic dimension
      width: dimension, // Use dynamic dimension
      backgroundColor: '#f4e3be',
      color: '#333',
      border: '1px solid #8b5c39',
      margin: '3px',
      fontSize: fontSize,
      fontWeight: 'bold',
      borderRadius: '5px',
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
    }}
  >
    <Typography 
      variant="h6" 
      style={{ 
        fontWeight: 'bold', 
        color: '#504538'
      }}
    >
      {letter}
    </Typography>
  </Paper>
);


export default LetterTile;