import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { database } from '../firebase';
import { ref, onValue, update } from 'firebase/database';
import { auth } from '../firebase';
import PlayerWords from './PlayerWords';
import GlobalMessage from './GlobalMessage';
import WordScramble from './WordScramble';
import RevealedLetters from './RevealedLetters';
import AddNewPlayer from './AddNewPlayer';
import { Button } from '@mui/material';
import Settings from './Settings'; 
import UserDisplay from './UserDisplay'; 
import ExplainerPopup from './ExplainerPopup'; 
import HomeLogo from './HomeLogo'; 
import GameOver from './GameOver';
import { calculateGameStats } from './GameOver';
import { submitGameResult } from './utils';



import validWordsUrl from '../validWords.txt';

const GamePage = () => {
    const { gameId } = useParams();
    const navigate = useNavigate();
    const [players, setPlayers] = useState([]);
    const [currentPlayer, setCurrentPlayer] = useState({});
    const [currentTurn, setCurrentTurn] = useState(0);
    const [letterPool, setLetterPool] = useState([]);
    const [revealedLetters, setRevealedLetters] = useState([]);
    const [previousLetterLength, setPreviousLetterLength] = useState(0);
    const [juggletWord, setJuggletWord] = useState('');
    const [localMessage, setLocalMessage] = useState('');
    const [globalMessage, setGlobalMessage] = useState({});
    const [wordsArray, setWordsArray] = useState('');
    const [playerWords, setPlayerWords] = useState({});
    const [selectedLetters, setSelectedLetters] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const [currentDelay, setCurrentDelay] = useState(0);
    const [settings, setSettings] = useState({});
    const [winningPlayerID, setWinningPlayerID] = useState('');
    const [gameOverMessage, setGameOverMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [showGameOverModal, setShowGameOverModal] = useState(false);
    const [gameStats, setGameStats] = useState(false);
    

     

    const playerContainerStyle = {
        display: 'flex',
        flexDirection: 'column', // This ensures vertical stacking
        alignItems: 'left', // Centers the children components
        justifyContent: 'flex-start',
        maxWidth: '600px', // Maximum width on larger screens
        margin: '20px auto', // Center align if the screen is wider than maxWidth
    };

    useEffect(() => {
        fetch(validWordsUrl)
            .then(response => response.text())
            .then(text => {
                const wordsArray = text.split('\n');
                setWordsArray(wordsArray); // Now this should log the actual words
            })
            .catch(error => {
                console.error('Error loading word list:', error);
            });
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            // If not a logged in user, get them to log in
            if (!user) {
                navigate(`/login?game=${gameId}`);
            }
            setIsAuthLoading(false);
        });

        return () => unsubscribe(); // Cleanup subscription
    }, [navigate, gameId]);
    
    useEffect(() => {
        if (gameId !== gameId.toUpperCase()) {
            navigate(`/game/${gameId.toUpperCase()}`);
        }

        if (isAuthLoading) return;

        const gameRef = ref(database, `games/${gameId}`);
        const unsubscribe = onValue(gameRef, (snapshot) => {
            const gameData = snapshot.val();
            if (gameData) {
                // Update your local state with the game data from Firebase
                setPlayers(gameData.players || []);
                setCurrentTurn(gameData.currentTurn || 0);
                setLetterPool(gameData.letterPool || []);
                setRevealedLetters(gameData.revealedLetters || []);
                setPlayerWords(gameData.playerWords || {});
                setGlobalMessage(gameData.globalMessage || {});
                setSettings(gameData.settings || {});
                setWinningPlayerID(gameData.winningPlayerID || "");
                setGameStats(gameData.gameStats || {});
            }
        });

        return () => unsubscribe();
    }, [gameId, navigate, isAuthLoading]);
    
    useEffect(() => {
        
        // If the revealed letters length has increased and we have at least one player
        if(revealedLetters.length > previousLetterLength && players[0] && currentPlayer.delay > 0) {
            setCurrentDelay(currentPlayer.delay);
        }

        // If the revealed letters length has decreased, let's clear out any entered word
        if(revealedLetters.length < previousLetterLength) {
            setSelectedLetters([]);
            setSelectedWords([]);
            setJuggletWord("");
        }
        
        setPreviousLetterLength(revealedLetters.length);
    }, [currentPlayer.delay, players, previousLetterLength, revealedLetters]);

    const getPlayerByID = useCallback((id) => {
        for (let player of players) {
            if(player.id === id) {
                return player;
            }
        }
        return null;
    }, [players]);

    useEffect(() => {
        if(players.length > 0 ) {
            const theCurrentPlayer = getPlayerByID(auth.currentUser.uid);

            // If currentPlayer isn't in list of players yet, let's redirect to have them join
            if(!theCurrentPlayer) {
                navigate(`/join/${gameId}`);
            } else {
                theCurrentPlayer.email = auth.currentUser.email;
                setCurrentPlayer(theCurrentPlayer);
            }
        }

    }, [players, getPlayerByID, gameId, navigate]);

    useEffect(() => {
        let newMsgTxt = "No winner!";;
        if(winningPlayerID !== "") {
            let winningPlayer = getPlayerByID(winningPlayerID);
            newMsgTxt = winningPlayer.name + " wins with a score of " + winningPlayer.score;
        } 
        setGameOverMessage(newMsgTxt);

    }, [winningPlayerID, players, getPlayerByID]);

    const handleDelayFinished = () => {
        setCurrentDelay(0);
    }

    const updateScores = (updatePlayerWords) => {
        let lowScore = 100;
        let highScore = -1;
        let winnerID = -1;
        let playersCopy = [...players];
            
        for (let player of playersCopy) {
            let score = 0;
            if(updatePlayerWords[player.id]) {
                for (let word of updatePlayerWords[player.id]) {
                    score += getWordScore(word);
                }
            }
            player.score = score;
            if(score > highScore) {
                winnerID = player.id;
                highScore = score;
            }
            if(score < lowScore) {
                lowScore = score;
            }
        }

        // 
        if(settings.delayType === "automatic") {
            for (let player of playersCopy) {
                player.delay = calcDelay(player.score, lowScore, highScore);
            }
        }

        setPlayers(playersCopy);
        updateFirestore({players: playersCopy,
                        winningPlayerID: winnerID});
    }
    
    const getWordScore = (word) => {
        // Score is simply word lenth minus 2, e.g. 3 letter word is worth 1 point etc.
        return word.length - 2;
    }

    const calcDelay = (score, lowScore, highScore) => {
        const scalingFactor = 5;
        const increments = 5;
        return Math.floor((score - lowScore)/scalingFactor) * increments;
    }

    const handleRevealLetter = () => {
        const newLetter = selectLetter(letterPool);
        const newRevealedLetters = [newLetter, ...revealedLetters];
        const newLetterPool = [...letterPool];
        let nextTurn = (currentTurn + 1) % players.length;

        // If there are no letters left in the pool, the game is over (set next turn to -1)
        if(newLetterPool.length === 0) {
            nextTurn = -1;
        }
        
        updateFirestore({
            letterPool: newLetterPool,
            revealedLetters: newRevealedLetters,
            currentTurn: nextTurn
        });

        if(settings.solo && settings.solo.isSolo) {
            clearSelection();
        }

        setLocalMessage("");
    };

    const handleGameOver = async () => {
        setSelectedLetters([]);
        setSelectedWords([]);
        setJuggletWord('');
        const newGlobalMessage = {'text':'', 'type':''};
        const winningPlayer = getPlayerByID(winningPlayerID);
        const stats = await calculateGameStats(winningPlayer, gameStats, players, playerWords, gameId);
        if(settings.solo && settings.solo.isSolo) {
            submitGameResult(winningPlayer, settings.solo);
        }
        
        updateFirestore({currentTurn: -2, 
                         globalMessage: newGlobalMessage,
                         gameStats: stats
                        });
        setShowGameOverModal(true);
    };

    const selectLetter = (pool) => {
        return pool.shift()
    };    

    const handleJuggletSubmit = async () => {
        setIsSubmitting(true);
        try {
            let newGlobalMessageText = currentPlayer.name + " guessed " + juggletWord;
            let newGlobalMessageType = "Incorrect";
            if (juggletWord.length < 3) {
                setLocalMessage("Sorry, your word must be at least 3 letters long.");
            } else if (selectedLetters.length === 0 && juggletWord.length === selectedWords[0].word.length) {
                    setLocalMessage("Sorry, you need to make a longer word.");
            } else if (!isActualWord()) {
                setLocalMessage("Sorry, that's not a valid word");
            } else {
                const sameWordStems = await hasSameWordStems();
                if (sameWordStems) {
                    setLocalMessage("Sorry, your word must be a new word, not a variation of an existing word.");
                } else {
                    // Otherwise, word is correct, so let's:

                    // 1. Let's remove used letters both locally and globally
                    removeLettersFromRevealed();    // Remove used letters
                    let updatedPlayerWords = {...playerWords};
                    updatedPlayerWords = removeSelectedWords(updatedPlayerWords);          // Remove selected words
                    
                    // 2. Add new word to current player
                    updatedPlayerWords = addWordForPlayer(currentPlayer.id, juggletWord, updatedPlayerWords);

                    // 3. Update scores
                    updateScores(updatedPlayerWords);

                    // 4. Update Firestore
                    updateFirestore({ playerWords: updatedPlayerWords });
                    setPlayerWords(updatedPlayerWords);


                    //3. Remove selected letters and words, clear out juggletWord and local messages
                    clearSelection();

                    // 5. Let msg everyone to let them know the word is correct
                    newGlobalMessageText += " (+"+ getWordScore(juggletWord) + ")";
                    newGlobalMessageType = "Correct";
                }
            }
            const newGlobalMessage = {'text':newGlobalMessageText, 'type':newGlobalMessageType};
            setGlobalMessage(newGlobalMessage);
            updateFirestore({ globalMessage: newGlobalMessage });
            setIsSubmitting(false);
        } catch (error) {
            console.error('Error:', error);
            setIsSubmitting(false);
        }
    };

    const clearSelection = async () => {
        setSelectedLetters([]);
        setSelectedWords([]);
        setLocalMessage(""); 
        setJuggletWord('');
    }

    const isActualWord = () => {
        return wordsArray.includes(juggletWord.toUpperCase());
    }

    const hasSameWordStems = async () => {
        for (let w of selectedWords) {
            const sameWordStem = await hasSameWordStem(juggletWord, w.word);
            if(sameWordStem) {
                return true;
            };
        }
        return false;
    }

    async function hasSameWordStem(word1, word2) {
        const url = `https://us-west1-jugglet.cloudfunctions.net/have_same_stem?word1=${encodeURIComponent(word1)}&word2=${encodeURIComponent(word2)}`;
    
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.have_same_stem;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const removeLettersFromRevealed = () => {
        let lettersCopy = [...revealedLetters];

        for (let x of selectedLetters) {
            let index = lettersCopy.indexOf(x.letter);
            if(index === -1) {
                console.error('Error: didnt find letter');
            }
            lettersCopy.splice(index, 1);
        }
        setRevealedLetters(lettersCopy);
        updateFirestore({ revealedLetters: lettersCopy });
    };

    const removeSelectedWords = (updatedPlayerWords) => {
        for (let w of selectedWords) {
            updatedPlayerWords = removeWordForPlayer(w.playerID, w.word, updatedPlayerWords);
        }
        return updatedPlayerWords;
    }

    const removeWordForPlayer = (playerID, word, updatedPlayerWords) => {

        // Check if the player exists in the object
        if (updatedPlayerWords.hasOwnProperty(playerID)) {
            let i = updatedPlayerWords[playerID].indexOf(word);
            if (i > -1) {
                updatedPlayerWords[playerID].splice(i, 1);
            }
        }
        return updatedPlayerWords;
    };

    const addWordForPlayer = (playerID, word, thePlayerWords) => {
        const updatedPlayerWords = {
            ...thePlayerWords,
            [playerID]: [...(playerWords[playerID] || []), word]
        };
        return updatedPlayerWords;
    };

    const updateFirestore = (updateObj) => {
        const gameRef = ref(database, `games/${gameId}`);
        update(gameRef, updateObj)
            .catch(error => console.error("Error updating Firestore: ", error));
    }

    // User has tapped on a revealed letter
    const handleSelectLetter = (letter, index) => {
        const letterObj = { letter, index };
        const isSelected = selectedLetters.some(l => l.letter === letter && l.index === index);
    
        if (isSelected) {
            setSelectedLetters(selectedLetters.filter(l => l.index !== index));
            setJuggletWord(juggletWord.replace(letter,''));
        } else {
            setSelectedLetters([...selectedLetters, letterObj]);
            setJuggletWord(juggletWord + letter);
        }
    };
    
    const handleSelectWord = (word, playerID, index) => {
        const wordObj = {word, playerID, index}
        const isSelected = selectedWords.some(w => w.word === word && w.index === index && w.playerID === playerID);
    
        if (isSelected) {
            setSelectedWords(selectedWords.filter(w => !(w.index === index && w.playerID === playerID)));
            let newJuggleWord = juggletWord;
            for (let letter of word.split('')) { 
                newJuggleWord = newJuggleWord.replace(letter,'');
            }
            setJuggletWord(newJuggleWord);
        } else {
            setSelectedWords([...selectedWords, wordObj]);
            setJuggletWord(juggletWord + word);
        }
    };

    const handleUpdatePlayers = (updatedPlayers) => {
        setPlayers(updatedPlayers);
        updateFirestore({ players: updatedPlayers });
    };

    const handleUpdateSettings = (newSettings) => {
        setSettings(newSettings);
        updateFirestore({ settings: newSettings });
    };

    const handleUpdateCurrentPlayer = (updatedCurrentPlayer) => {
        console.log(updatedCurrentPlayer.name);
        setCurrentPlayer(updatedCurrentPlayer);
        const updatedPlayers = [...players];
        for (let player of updatedPlayers) {
            if(player.id === updatedCurrentPlayer.id) {
                player.name = updatedCurrentPlayer.name;
            }
        }

        setPlayers(updatedPlayers);
        updateFirestore({ players: updatedPlayers });
    };

    return (
        <div style={{ overflowX: 'hidden' }}>
            <ExplainerPopup showPopup={false}/>
            <UserDisplay currentPlayer={currentPlayer} onUpdateCurrentPlayer={handleUpdateCurrentPlayer}/>
            <Settings players={players} settings={settings} onUpdatePlayers={handleUpdatePlayers} onUpdateSettings={handleUpdateSettings}/>
            <HomeLogo />
            <GlobalMessage 
                msg={globalMessage}
                currentTurn={currentTurn}
                gameOverMessage={gameOverMessage}
                handleGameOver={handleGameOver}
                settings={settings}
            />
            <RevealedLetters letters={revealedLetters} 
                onLetterSelect={handleSelectLetter} 
                selectedLetters={selectedLetters}
                currentDelay={currentDelay}
                onDelayFinished={handleDelayFinished}
                currentTurn={currentTurn}
                currentTurnPlayer={players[currentTurn]}
                thisPlayer={currentPlayer}
                onRevealLetter={handleRevealLetter}
                letterPoolCount={letterPool.length}
                players={players}
                settings={settings}
            />
            <div style={{ textAlign: 'center', margin:'20px'}} >
                <WordScramble 
                    word={juggletWord} 
                    onWordScramble={setJuggletWord}
                    currentDelay={currentDelay || 0}
                    revealedLetters={revealedLetters}
                    playerWords={playerWords}
                    currentTurn={currentTurn}
                />
                {juggletWord && !(currentDelay >0) && 
                    <>
                    <Button onClick={handleJuggletSubmit} 
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                            >Enter
                    </Button>
                    <Button sx={{margin:'0 30px;'}} onClick={clearSelection} 
                    disabled={isSubmitting}
                    >Clear
                    </Button>
                    </>
            }
                {localMessage && !(currentDelay >0) && <p>{localMessage}</p>}
            </div>
            <style>
                {`
                .playerContainer {
                    padding: 3px;
                    width: '95%';
                }

                @media (min-width: 768px) {
                    .playerContainer {
                    padding: 20px;
                    width: '100%';
                    }
                }
                `}
            </style>
            <div style={playerContainerStyle} className="playerContainer">
                {players.sort((a, b) => b.score - a.score).map(player => (
                    <PlayerWords 
                        key={player.id}
                        player={player} 
                        words={playerWords[player.id] || []} 
                        onWordSelect={handleSelectWord}
                        selectedWords={selectedWords}
                        isCurrentPlayer={player.id === currentPlayer.id}
                        currentDelay={currentDelay}
                        currentTurn={currentTurn}
                    />
                ))}
                {!(settings.solo && settings.solo.isSolo) &&
                <AddNewPlayer players={players}/>}
            </div>
            {/* <MessageBoard gameId={gameId} /> */}
            {showGameOverModal && gameStats &&
                <GameOver 
                    stats={gameStats} 
                    onGameOverClosed={() => setShowGameOverModal(false)}
                />
            }
        </div>
    );
};

export default GamePage;