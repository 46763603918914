import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LetterTile from './LetterTile';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const WordScramble = ({ word, onWordScramble, currentDelay, revealedLetters, playerWords, currentTurn }) => {
  const [letters, setLetters] = useState(word.split(''));
  const [isMounted, setIsMounted] = useState(false);
  const [fontSize, setFontSize] = useState("20px");
  const [tileDimension, setTileDimension] = useState("50px");
  const [totalWordCount, setTotalWordCount] = useState(0);
  
  document.body.style.overflowX = 'hidden'; // Disable scrolling

  useEffect(() => {
    setIsMounted(true);
  }, []);


  useEffect(() => {
    let totalLength = 0;

    for (const key in playerWords) {
      if (playerWords.hasOwnProperty(key)) {
          totalLength += playerWords[key].length;
      }
    }
    setTotalWordCount(totalLength);
  }, [playerWords]);

  useEffect(() => {
    setLetters(word.split(''));
    let newSize = '20px';
    let newDimension = '50px';
  
    if (word.length > 4) {
      newSize = '16px';
      newDimension = '40px';
    }
    if (word.length > 8) {
      newSize = '12px';
      newDimension = '30px';
    }
    if (word.length > 10) {
      newSize = '10px';
      newDimension = '25px';
    }
  
    setFontSize(newSize);
    setTileDimension(newDimension); // New state for tile dimension
  }, [word]);

  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }

    const newLetters = reorder(
      letters,
      result.source.index,
      result.destination.index
    );

    setLetters(newLetters);
    onWordScramble(newLetters.join(''));
  };

  return (
    currentTurn >= -1 ? (
      <DragDropContext onDragEnd={onDragEnd}>
        {letters.length > 0 && isMounted && currentDelay === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  style={{ display: 'flex' }}
                  {...provided.droppableProps}
                >
                  {letters.map((letter, index) => (
                    <Draggable
                      key={index}
                      draggableId={String(index)}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <LetterTile
                            letter={letter}
                            fontSize={fontSize}
                            dimension={tileDimension}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
        {letters.length === 0 && currentDelay === 0 && revealedLetters && (
          <div style={{ fontStyle: 'italic', color: '#aaaaaa' }}>
            {playerWords && Object.keys(playerWords).length > 0 && totalWordCount < 5 ? (
              <span>Combine a word below with letters above & create a new word</span>
            ) : (
              revealedLetters.length >= 3 && totalWordCount < 5 ? (
                <span>Pick letters above to spell a word</span>
              ) : (
                <></>
              )
            )}
          </div>
        )}
      </DragDropContext>
    ) : (
      <></>
    )
  );  
};

export default WordScramble;
