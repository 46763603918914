import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';

const Settings = ({ players, onUpdatePlayers, settings, onUpdateSettings }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangeDelay = (playerId, delay) => {
        const updatedPlayers = players.map(player => 
            player.id === playerId ? { ...player, delay } : player
        );
        onUpdatePlayers(updatedPlayers);
    };

    const handleChangeDelayType = (event) => {
        const delayType = event.target.value;
        const newSettings = { ...settings, delayType: delayType};
        const updatedPlayers = [...players];
        // If not manual, set all delays to zero
        if (delayType !== "manual") {
            updatedPlayers.map(player => player.delay = 0);
        }
        onUpdatePlayers(updatedPlayers);
        onUpdateSettings(newSettings);
    };

    return (
        <div style={{ position: 'absolute', top: '12px', right: '70px' }}>
            <Button 
                onClick={handleOpen}
                style={{ margin:'0', padding:'5px 10px', color: 'grey', borderColor: 'lightgray' }} 
            > 
                <SettingsIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Player Settings</DialogTitle>
                <DialogContent>
                    <Box paddingBottom={2} textAlign="left"> {/* Add Box for alignment and spacing */}
                        <Box paddingBottom={1}>Delay type:</Box>
                        <Select
                            value={settings.delayType || 'none'}
                            onChange={handleChangeDelayType}
                            fullWidth
                            variant="outlined" // Optional: for outlined style
                            margin="dense" // Optional: for smaller field size
                        >
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="manual">Manual</MenuItem>
                            <MenuItem value="automatic">Automatic</MenuItem>
                        </Select>
                    </Box>
                    {settings.delayType === "manual" &&
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Player Name</TableCell>
                                <TableCell>Time Delay</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {players.map(player => (
                                <TableRow key={player.id}>
                                    <TableCell>{player.name}</TableCell>
                                    <TableCell>
                                        <Select
                                            value={player.delay || 0}
                                            onChange={(e) => handleChangeDelay(player.id, e.target.value)}
                                            fullWidth
                                        >
                                            <MenuItem value={0}>0 seconds</MenuItem>
                                            <MenuItem value={5}>5 seconds</MenuItem>
                                            <MenuItem value={10}>10 seconds</MenuItem>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Settings;