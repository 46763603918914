import React from 'react';
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Button, Container, Box, Typography, Link } from '@mui/material';
import HomeLogo from './HomeLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import { database } from '../firebase';
import { ref, set, get } from 'firebase/database';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const gameID = searchParams.get('game');
    const solo = searchParams.get('solo');

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
    
            const userRef = ref(database, `users/${user.uid}`);
            const snapshot = await get(userRef);
            if (!snapshot.exists()) {
                set(userRef, { name: user.displayName, email: user.email });
            }
            if(gameID) {
                let gameURL = `/join/${gameID}`;
                if(solo) {
                    gameURL += '&solo=1';
                }
                navigate(gameURL);
            } else {
                navigate('/');
            }
            
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <HomeLogo />
            <Container maxWidth="sm">
                
                <Box my={4} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4" gutterBottom>
                        Login
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={googleSignIn}
                        style={{
                            marginTop: 16,
                            background: 'white',
                            color: 'rgba(0, 0, 0, 0.87)', // Darker text for readability
                            boxShadow: 'none',
                            textTransform: 'none',
                            fontSize: 16,
                            padding: '12px 24px',
                            border: '1px solid #e0e0e0', // Light gray border
                            borderRadius: '4px', // Slightly rounded corners
                            lineHeight: 1.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: '500',
                        }}
                    >
                        <img src="/google-logo.png" alt="Google logo" style={{ marginRight: 10, width:30 }} />
                        Sign in with Google
                    </Button>
                    <Typography variant="body1" style={{ marginTop: 16 }}>
                        or <Link href={`/guest?game=${gameID}`}>Continue as guest</Link>
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default Login;
