import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close'; 
import TextField from '@mui/material/TextField';
import { database } from '../firebase';
import { ref, update } from 'firebase/database';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const menuStyle = { 
    position: 'absolute', 
    top: '10px', 
    right: '30px',
    zIndex: 'tooltip',
    color: 'grey'
};

const UserDisplay = ({currentPlayer, onUpdateCurrentPlayer}) => {
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState(currentPlayer?.name || '');
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setShowModal(false); 
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleNameEdit = () => {
    setEditMode(true);
  };

  const handleNameSave = async () => {
    if (newName !== currentPlayer.name) {
      currentPlayer.name = newName;
      const userRef = ref(database, `users/${currentPlayer.id}`);
      await update(userRef, { name: newName })
            .catch(error => console.error("Error updating Firestore: ", error));
      
      onUpdateCurrentPlayer(currentPlayer);
    }
    setEditMode(false);
  };

  return (
    <>
      <Box sx={menuStyle}>
        {currentPlayer ? (
          <IconButton onClick={() => setShowModal(true)} color="inherit">
            <AccountCircleIcon />
            {/* <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
              {currentPlayer.name || 'Guest'}
            </Typography> */}
          </IconButton>
        ) : (
          <Button color="inherit" onClick={() => navigate('/login')}>
            Login
          </Button>
        )}
      </Box>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="user-details-modal"
        aria-describedby="user-details-modal-description"
      >
        <Box sx={modalStyle}>
          {editMode ? (
            <Box>
              <TextField
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                size="small"
                autoFocus
              />
              <Button onClick={handleNameSave} color="primary">
                Save
              </Button>
            </Box>
          ) : (
            <>
              <IconButton
                onClick={() => setShowModal(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
              {currentPlayer && currentPlayer.name && (
              <Typography id="user-details-modal-description" sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                Name: {currentPlayer?.name}
                <Button
                  onClick={handleNameEdit}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  Edit
                </Button>
              </Typography>
            )}
          {currentPlayer && currentPlayer.email &&
          <>
            <Typography id="user-details-modal-description" sx={{ mt: 2 }}>
              Email: {currentPlayer?.email}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2
              }}
            >
              <Button
                onClick={handleSignOut}
                variant="contained"
                color="primary"
              >
                Log Out
              </Button>
            </Box>
          </>}
          </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UserDisplay;
