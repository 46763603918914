import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography, Container, Box, Grid, Link, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { getNewGameURL } from './utils';
import ExplainerPopup from './ExplainerPopup'; 
import SoloSettingsModal from './SoloSettingsModal';
import Leaderboard from './Leaderboard';

const getSubdomain = () => {
    const hostname = window.location.hostname;
    // Split hostname by the periods
    const parts = hostname.split('.');

    // Check if there are more than two parts (subdomain.domain.tld)
    if (parts.length >= 3) {
        // Assuming the format is [subdomain].[domain].[tld]
        return parts[0];
    }

    // Return null or an empty string if no subdomain
    return null;
};

const MainPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);
    const [poolSize, setPoolSize] = useState(50);
    const [windowSize, setWindowSize] = useState(5);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const subdomain = getSubdomain();
    const isSolo = location.pathname === '/solo' || subdomain === 'solo';

    const createGame = () => {
        const settings = {solo:{isSolo: isSolo, 
                          poolSize: poolSize, 
                          windowSize: windowSize}};
        const newGameURL = getNewGameURL(settings);
        navigate(newGameURL);
    };

    useEffect(() => {
        setShowPopup(location.pathname === '/explain');
    }, [location]);

    const handleOpenSettings = () => {
        setSettingsOpen(true);
    };

    const handleCloseSettings = () => {
        setSettingsOpen(false);
    };

    const handleUpdateSettings = (newPoolSize, newWindowSize) => {
        setPoolSize(newPoolSize);
        setWindowSize(newWindowSize);
    };
    
    return (
        <Container maxWidth="sm">
            <ExplainerPopup showPopup={showPopup} />
            {isSolo && (
                <IconButton color="primary" onClick={handleOpenSettings} style={{ position: 'absolute', right: 16, top: 16 }}>
                    <SettingsIcon />
                </IconButton>
            )}
            <SoloSettingsModal
              open={settingsOpen}
              handleClose={handleCloseSettings}
              defaultPoolSize={poolSize}
              defaultWindowSize={windowSize}
              handleUpdate={handleUpdateSettings}
            />
            <Box my={4} display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h2" component="h1" gutterBottom>
                    {isSolo ? "solo spellago" : "spellago"}
                </Typography>
                <Typography variant="subtitle1">another word game</Typography>
                <Grid container spacing={2} justifyContent="center" style={{ marginTop: 16 }}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={createGame}>
                            Play New Game
                        </Button>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2">
                        Suggestions? Issues? <Link href="mailto:spellago@gmail.com" color="inherit">Contact us</Link>
                    </Typography>
                </Box>
                {isSolo && <Leaderboard />}
            </Box>
        </Container>
    );
};

export default MainPage;
