import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import GamePage from './pages/GamePage';
import JoinGamePage from './pages/JoinGamePage';
import Login from './pages/Login';
import Logout from './pages/Logout';
import SignUp from './pages/SignUp';
import Guest from './pages/Guest';
import Leaderboard from './pages/Leaderboard';
import FinishSignUp from './pages/FinishSignUp';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/solo" element={<MainPage />} />
                <Route path="/game/:gameId" element={<GamePage />} />
                <Route path="/join/:gameId" element={<JoinGamePage />} />
                <Route path="/finishSignUp" element={<FinishSignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/guest" element={<Guest />} />
                <Route path="/explain" element={<MainPage />} />
                <Route path="/scores" element={<Leaderboard />} />
            </Routes>
        </Router>
    );
};

export default App;