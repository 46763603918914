import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ExplainerPopup = ({showPopup}) => {
  const [open, setOpen] = useState(showPopup || false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(showPopup);
  }, [showPopup]);

  const explainerContainerStyle = { 
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: '75%'
  };

  return (
    <div style={{ position: 'absolute', top: '10px', right: '135px' }}>
      <IconButton onClick={handleOpen}>
        <HelpOutlineIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="spellago-rules-title"
        aria-describedby="spellago-rules-description"
      >
        <Box sx={explainerContainerStyle}>
          <Typography id="spellago-rules-title" variant="h6" component="h2">
            Quick Guide
          </Typography>
          <Typography id="spellago-rules-description" sx={{ mt: 2 }}>
            <ul>
              <li><strong>Turn-Based Play:</strong> Players take turns to reveal new letters.</li>
              <li><strong>Create Words:</strong> Use 3 or more letters to form a word (e.g., T, C, A → CAT).</li>
              <li><strong>Scoring:</strong> Points equal word length minus 2. Longer words score higher (e.g., CAT = 1, RED = 1, TRACED = 4).</li>
              <li><strong>Word Stealing:</strong> Add a new letter to another player's word to steal it. The new word must be different in base meaning (e.g., Player A has "CAT", Player B can steal with "ACTS" or "CAST", but not "CATS").</li>
              <li><strong>Winning:</strong> The player with the most points when letters run out wins!</li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ExplainerPopup;