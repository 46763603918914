import React, { useRef } from 'react';

const PlayerWords = ({ player, words, onWordSelect, selectedWords, isCurrentPlayer, currentDelay,
    currentTurn}) => {
    const sortedWords = [...words].sort((a, b) => a.length !== b.length ? a.length - b.length : a.localeCompare(b));
    const playerSelectedWords = selectedWords.filter(item => item.playerID === player.id).map(item => item.word); 
    const ref = useRef(null);
    
    const tileStyle = {
        backgroundColor: '#eeeeee', // grey background
        color: '#333',
        margin: '3px',
        border: '1px solid #888888',
        fontWeight: 'bold',
        borderRadius: '5px',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        padding: '2px 10px',
        height: '40px',
        fontSize: '16px',
        textTransform: 'none', // Prevents uppercase transformation in MUI Button
    };

    const wordListStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: '20px',
    };

    // Style for the player's container
    const playerContainerStyle = {
        padding: '10px', // Padding around the content
        //borderRadius: '10px', // Rounded corners
        borderBottom: '1px solid #EEEEEE',
        margin: '0 0 30px 0', // Margin at the bottom to separate players
    };

    // Updated playerNameStyle with additional styles
    const playerNameStyle = {
        fontWeight: 'bold', // Bold for emphasis
        marginBottom: '10px', // Margin bottom to separate from the word list
        display: 'flex', // Use flexbox for alignment
        justifyContent: 'space-between', // Space between name and points
        alignItems: 'center', // Align items vertically
    };

    return (
        <div ref={ref} style={playerContainerStyle}>
            <div style={playerNameStyle}>
                <span>{player.name} {isCurrentPlayer && <span>(you)</span>}</span>
                <span>{player.score} points</span>
            </div>
            <div style={wordListStyle}>
                {sortedWords.map((word, index) => (
                    <button 
                        key={index}
                        onClick={() => onWordSelect(word, player.id, index)}
                        style={{
                            ...tileStyle,
                            ...(playerSelectedWords.includes(word) ? { backgroundColor: 'lightgreen' } : {}),
                            ...(currentDelay > 0 ? { opacity: 0.5, cursor: 'not-allowed' } : {})
                        }}
                        disabled={currentDelay > 0 || currentTurn === -2}
                    >
                        {word}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PlayerWords;
