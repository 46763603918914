import React, { useState } from 'react';
import Button from '@mui/material/Button';

const AddNewPlayer = ({ players }) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 3000); // Message disappears after 3 seconds
            })
            .catch(err => console.error('Failed to copy URL: ', err));
    };
    
    const isPrimary = players && players.length === 1; // Check if there's only one player and players is not undefined

    return (
        <div style={{ margin: '100px 0', textAlign: 'center' }}>
            <Button 
                onClick={handleCopyClick}
                variant={isPrimary ? "contained" : "outlined"}
                color={isPrimary ? "primary" : "inherit"} // Ensure color prop receives either "primary" or "inherit"
                style={{ 
                    margin: '10px 0', 
                    padding: '5px 10px', 
                    color: isPrimary ? 'white' : 'grey', 
                    borderColor: isPrimary ? undefined : 'lightgray' 
                }}
            >
                + Add New Player
            </Button>
            {copySuccess && (
                <div style={{ color: 'gray' }}>
                    Game URL copied. <br/>
                    Send it to others so they can join.
                </div>
            )}
        </div>
    );
};

export default AddNewPlayer;
