import React, { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; 

const Logout = () => {
  
  const auth = getAuth();
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    signOut(auth).then(() => {
      navigate("/");
    }).catch((error) => {
      console.error(error);
    });
}, [auth, navigate]);

  return (
    <></>
  );
};

export default Logout;
