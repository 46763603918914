import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyALxZXpWSvKUdrcKruWARHbW1ZmAOcn3Do",
    authDomain: "jugglet.firebaseapp.com",
    projectId: "jugglet",
    storageBucket: "jugglet.appspot.com",
    messagingSenderId: "822075467635",
    appId: "1:822075467635:web:4c96ca9cac7b6697238706",
    measurementId: "G-F73TMKWHHG",
    databaseURL: "https://jugglet-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
export { database }; 

const auth = getAuth(app);
export {auth};

const analytics = getAnalytics(app);
