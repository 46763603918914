import { database } from '../firebase';
import { ref, get, set, child } from 'firebase/database';
import { auth } from '../firebase';

export const fetchPlayerData = async (uid) => {
    const playerRef = ref(database, `users/${uid}`); // Adjust the path according to your data structure

    try {
        const snapshot = await get(playerRef);
        if (snapshot.exists()) {
            const playerData = snapshot.val();
            return playerData;
        } else {
            console.log('No player data available');
            return null; 
        }
    } catch (error) {
        console.error("Error fetching player data: ", error);
    }
};

export const getNewGameURL = (settings) => {
    let isSolo, poolSize, windowSize;
    if(settings.solo && settings.solo.isSolo) {
        isSolo = settings.solo.isSolo;
        poolSize = settings.solo.poolSize;
        windowSize = settings.solo.windowSize;
    };

    var randomString = generateRandomString(8);
    let newGameURL = '';

    // Check if user is authenticated
    if (auth.currentUser) {
        newGameURL = `/join/${randomString}`;
        if(isSolo) {
            newGameURL += `?solo=1&pool=${poolSize}&window=${windowSize}`;
        }
    } else {
        // Redirect to login page if not authenticated
        newGameURL = `/login?game=${randomString}`;
        if(isSolo) {
            newGameURL += `&solo=1&pool=${poolSize}&window=${windowSize}`;
        }
    }
    return newGameURL;
};

function generateRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const submitGameResult = async (player, soloSettings) => {
    console.log(player);
    console.log(soloSettings);
    const timestamp = new Date().toISOString();
    const dateString = timestamp.split('T')[0].replace(/-/g, '');
  
    // Path for the new game result
    const gameResultRef = ref(database, 'gameResults/' + Date.now()); // Using Date.now() to generate a unique key
  
    // Add game result
    await set(gameResultRef, {
      id:player.id,
      playerName: player.name,
      score:player.score,
      poolSize:soloSettings.poolSize,
      windowSize:soloSettings.windowSize,
      timestamp
    });
  
    // Path for daily best score
    const dailyBestKey = `${dateString}_${player.id}_${soloSettings.windowSize}_${soloSettings.poolSize}`;
    const dailyBestRef = ref(database, 'dailyBestScores/' + dailyBestKey);
  
    // Check and update daily best score
    const dailyBestSnap = await get(dailyBestRef);
    if (!dailyBestSnap.exists() || dailyBestSnap.val().bestScore < player.score) {
      await set(dailyBestRef, {
        bestScore: player.score,
        playerName: player.name,
        poolSize:soloSettings.poolSize,
        windowSize:soloSettings.windowSize,
        dateString:dateString,
        timestamp
      });
    }
  };
