import { Button } from '@mui/material';
import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { getNewGameURL } from './utils';
import { useNavigate } from 'react-router-dom';

const GlobalMessage = ({ msg, currentTurn, gameOverMessage, handleGameOver, settings }) => {
    const navigate = useNavigate();

    // Styles for the container of the countdown timer
    const countdownContainerStyle = {
        display: 'flex', // Use flexbox
        justifyContent: 'center', // Center content horizontally
        textAlign: 'center',
        flexDirection: 'column', // Stack items vertically
        alignItems: 'center', // Center content vertically (optional)
        height: '100px', // Adjust as needed for vertical spacing
        margin: 'auto', // Ensure margin is auto for horizontal centering
        padding: '50px'
    };

    const countdownMessageStyle ={
        margin: '30px' 
    }

    const gameOverStyle = {
        fontSize: '30px',
        textAlign: 'center',
        margin: '30px'
    }

    const currentDelay = 60;

    const newGame = () => {
        const newGameURL = getNewGameURL(settings);
        navigate(newGameURL);
    };

    return (
        <>
        {msg && msg.text &&
            <div style={{textAlign: 'center'}}>
                {msg.type === "Correct" ? (
                    <div style={{ color: 'green' }}>{msg.text}</div>
                ) : (msg.type === "Incorrect" ? (
                    <div style={{ color: 'red' }}>{msg.text}</div>
                ) : (
                    <div>{msg.text}</div>
                ))}
            </div>
        }
        {currentTurn === -1 &&
        <div style={countdownContainerStyle}>
            <div style={countdownMessageStyle}>No letters left! <br/>Make final moves before time runs out!</div>
            <CountdownCircleTimer
                isPlaying={currentTurn === -1}
                duration={currentDelay}
                size={50}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}
                onComplete={() => {
                    handleGameOver();
                }}
            >
                {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
        </div>}
        {currentTurn === -2 && gameOverMessage &&
        <div style={gameOverStyle}>
            GAME OVER!<br/>
            <Button onClick={newGame} >New Game</Button>
            <Button onClick={handleGameOver} >Show Stats</Button>
        </div>}
        </>
    );
};

export default GlobalMessage;